%input {
  background: var(--bars-bg);
  border: 1px solid var(--input-bg);
  height: 4rem;
  border-radius: var(--border-radius-round);

  &:focus {
    border: 1px solid var(--input-bg);
    border-radius: var(--border-radius-round);
    box-shadow: none;
  }
}
