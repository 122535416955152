$main-font: 'Roboto', 'sans-serif';

$accent-primary: #005FFF;
$accent-error: #FF3742;
$accent-info: #20E070;

:root {
  --main-font: #{$main-font};
  --second-font: #{$main-font};

  --xs-font: 10px;
  --sm-font: 12px;
  --md-font: 14px;
  --lg-font: 16px;
  --xl-font: 22px;
  --xxl-font: 24px;
  --xxxl-font: 34px;

  --accent-primary: #{$accent-primary};
  --accent-error: #{$accent-error};
  --accent-info: #{$accent-info};
  --static-white: #ffffff;

  .light {
    --app-bg: #ffffff;
    --bars-bg: #ffffff;
    --input-bg: #e9eaed;
    --link-bg: #E0F0FF;
    --text-high-emphasis: #000000;
    --text-low-emphasis: #72767e;
    --border-color: #dbdde1;
    --borders: #DBDDE1;
    //--grey-gainsboro: var(--bars-bg);
  }

  .dark {
    --app-bg: #000000;
    --bars-bg: #121416;
    --input-bg: #1c1e22;
    --link-bg: #00193D;
    --text-high-emphasis: #ffffff;
    --text-low-emphasis: #72767e;
    --border-color: #272a30;
    --borders: #272A30;
    //--grey-gainsboro: var(--bars-bg);
  }
}
