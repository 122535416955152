%dialog {
  position: absolute;
  background: var(--bars-bg);
  white-space: nowrap;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.15));
  border-radius: var(--border-radius-md);
}

%dropdown {
  .dropdown-anchor {
    z-index: 1;
    position: relative;
  }
  ul.dropdown-list {
    @extend %dialog;
    border-radius: 0 0 4px 4px;
    li {
      padding: var(--xs-p) var(--sm-p);
      cursor: pointer;
      &:hover {
        background: var(--border-color);
      }
    }
  }
}
