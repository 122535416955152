@use "base";
@use "variables";

@mixin button($bg, $color,$border) {
  @extend %center-container;
  background: $bg;
  color: $color;
  @if $border {
    border: 1px solid $border;
  }
  cursor: pointer;
}

%button-with-icon {
  svg {
    margin-right: 10px;
  }
}

%button-light {
  @include button(
      var(--bars-bg),
      var(--text-low-emphasis),
      var(--border-color)
  );
}

%button-48 {
  height: 48px;
  padding: 14px 24px;
  width: 100%;
  border-radius: var(--border-radius-md);
}

%button-icon-styles {
  svg, path {
    fill: var(--text-low-emphasis);
  }

  &:hover {
    svg, path {
      fill: var(--text-high-emphasis);
    }
  }
}
