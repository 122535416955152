@use 'typography';

%channel-preview-title {
  margin-bottom: 0.7rem; // todo: fix: this should be done via variable
}

.str-chat__channel-list-messenger,
.str-chat.str-chat-channel-list.messaging {
  background: var(--bars-bg) !important;
}

.str-chat .str-chat__channel-preview-messenger--active {
  background: var(--border-color) !important;
}


.str-chat-channel-list--open.messaging {
  right: 0;
}

.str-chat.str-chat-channel-list {
  display: flex;
  flex-direction: column;

  .str-chat__channel-list-messenger {
    flex: 1 1;
    overflow-y: auto;
  }
}
.str-chat-channel-list .str-chat__channel-list-messenger__main {
  padding: 0;

  .str-chat__channel-preview-messenger--active,
  .str-chat__channel-preview-messenger {
    box-shadow: none;
    border-radius: unset;
    margin: 0;
    padding: 1.15rem 0.8rem; // todo: should not be hardcoded

    &:hover {
      background: var(--border-color);
    }
  }

  .str-chat__channel-preview-messenger--name {
    @extend %channel-preview-title;
  }
}

.str-chat__header-livestream-left--title {
  @extend %subtitle-1;
}
