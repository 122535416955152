@use "base";
@use "variables";
@use "button";

.wc-authenticator-box {
  border-radius: var(--border-radius-md);
  border: 1px solid var(--border-color);
  width: 46.5rem;
  padding: 3.2rem;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: none;
    flex: 1;
    padding: 0;
  }

  .wc-welcome {
    @extend %align-vertical-container;
    margin: 1.5rem 0 8rem;
    h1 {
      @extend %headline-6;
      color: var(--text-high-emphasis);
    }

    h2 {
      @extend %subtitle-1;
      color: var(--text-low-emphasis);
      margin: 0 .8rem;
    }

    svg {
      height: 8rem;
      width: 8rem;
    }
  }

  .wc-login-btn {
    @extend %button-light;
    @extend %button-48;
    @extend %button-with-icon;

    @media screen and (max-width: 960px) {
      max-width: 30rem;
    }

  }
}

.wc-authenticator-footer {
  @extend %caption;
  @extend %center-container;
  justify-content: space-between;
  padding: 1.6rem;
  width: 46.5rem;

  .wc-authenticator-footer-link-group {
    display: flex;
    justify-content: space-between;
    width: 16rem;

    a {
      color: var(--text-low-emphasis);
    }
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    max-width: 32rem;
  }
}


