.str-chat__message-attachment-card {
  background: var(--bars-bg);
  font-size: var(--sm-font);

  &--header {
    height: unset;
  }

  &--title {

  }

  &--flex {
    padding: var(--xs-p);
  }

  &--content {
    width: 100%;
    display: flex;
    padding: 0;
    margin: 0;

    img {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }

    > * {
      margin: 0;
    }

  }

  &--url {

    &::after {
      //display: none;
    }
  }

}


