$assetsPath: '../assets/fonts/';

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
      url($assetsPath + 'roboto-v29-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
      url($assetsPath + 'roboto-v29-latin-regular.woff') format('woff'), /* Modern Browsers */
      url($assetsPath + 'roboto-v29-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
      url($assetsPath + 'roboto-v29-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local(''),
      url($assetsPath + 'roboto-v29-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
      url($assetsPath + 'roboto-v29-latin-italic.woff') format('woff'), /* Modern Browsers */
      url($assetsPath + 'roboto-v29-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
      url($assetsPath + 'roboto-v29-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(''),
      url($assetsPath + 'roboto-v29-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
      url($assetsPath + 'roboto-v29-latin-500.woff') format('woff'), /* Modern Browsers */
      url($assetsPath + 'roboto-v29-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
      url($assetsPath + 'roboto-v29-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

%headline-4 {
  line-height: 3.4rem;
  font-size: 3.4rem;
  font-weight: 400;
}

%headline-5 {

}

%headline-6 {
  line-height: 2.4rem;
  font-size: 2rem;
  font-weight: 500;
}

%subtitle-1 {
  line-height: 2rem;
  font-size: 1.6rem;
  font-weight: 400;
}
%subtitle-1-italic {

}
%subtitle-1-bold {

}

%body-2 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

%caption {
  line-height: 1.6rem;
  font-size: 1.2rem;
  font-weight: 400;
}

