@use "button";
@use "typography";

.splash-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--link-bg);
  color: var(--text-high-emphasis);
  height: 100vh;
  width: 100vw;

  .title {
    text-align: center;
    @extend %headline-4;
  }

  .button {
    @extend %button-light;
    border-radius: var(--border-radius-md);
    padding: var(--xs-p) var(--sm-p);
    margin-top: 2rem;
  }
}
