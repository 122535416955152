@use "button";
@use "dropdown";
@use "variables";

.str-chat__message-input-actions {

  svg.lightning.active, svg.lightning.active:hover {
    path {
      fill: var(--accent-primary);
    }
  }

  .str-chat__message-input-actions-button {
    @extend %button-icon-styles;
    border: none;
    background: var(--bars-bg);
    position: relative;
    margin: 1rem;
    cursor: pointer;
  }

  .str-chat__message-input-actions-box-anchor {
    position: relative;
    height: 0;

    .str-chat__message-input-actions-box {
      @extend %dialog;
      width: 35rem;
      position: absolute;
      bottom: 2rem;
      left: -2rem;

      font-size: 1.6rem;

      .str-chat__message-input-actions-header {
        display: flex;
        align-items: center;
        padding: var(--sm-p) var(--xs-p) 0;
        color: var(--text-low-emphasis);

        svg.lightning {
          margin: 0 1.5rem 0 .5rem;
        }
      }

      li {
        cursor: pointer;
        padding: var(--sm-p) var(--xs-p);

      }

    }
  }


}

.str-chat__giphy-header {
  display: flex;
  align-items: center;
  color: var(--text-low-emphasis);

  .str-chat__giphy-header--name {
    @extend %headline-6;
    color: var(--text-high-emphasis); // todo: duplicate style with wc-welcome in authenticator
    margin-left: .8rem;
  }

  .str-chat__giphy-header--hint {
    @extend %subtitle-1
  }

}
