.str-chat__loading-channels {
  background: var(--bars-bg);

  &-avatar,
  &-username,
  &-status {
    background-image: linear-gradient(
        -90deg,
        var(--bg-gradient-start) 0%,
        var(--bg-gradient-end) 100%
    );
  }
}
