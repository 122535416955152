@use 'typography';

.messaging.str-chat .str-chat__header-livestream {
  border-radius: 0;
  box-shadow: none;
  background: var(--bars-bg) !important;
  padding: 1rem 0.8rem; // todo: fix: this should be done via variable
}

.str-chat__header-livestream .str-chat__avatar {
  margin-right: 1.8rem; // todo: fix: this should be done via variable
}

.str-chat__header-livestream-left--members {
  @extend %body-2;
  color: var(--text-low-emphasis);
}

.str-chat__header-hamburger {
  width: initial;
  height: initial;
  padding: 0.6rem;
  margin-right: 6px;

  path {
    fill: var(--text-low-emphasis);
  }
}
