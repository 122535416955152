$border-radius: calc(var(--border-radius) + 2px);
$border-radius--last-bubble-left: #{$border-radius} #{$border-radius} #{$border-radius} 0;
$border-radius-last-bubble-right: #{$border-radius} #{$border-radius} 0 #{$border-radius};

.str-chat__message-text-inner,
.str-chat__message-simple-text-inner {
  font-size: 1.6rem;
  line-height: 2rem;
  padding: var(--xs-p) var(--sm-p);
  background: var(--input-bg) !important;

  &--is-emoji p {
    line-height: 2rem !important;
  }
}

.str-chat__li {
  &--top,
  &--middle {
    .str-chat__message,
    .str-chat__message--me {
      .str-chat__message-text-inner {
        border-radius: #{$border-radius};
      }
    }
  }

  &--bottom,
  &--single {
    .str-chat__message {

      > .str-chat__avatar,
      > .str-chat-angular__avatar-host > .str-chat__avatar {
        margin-bottom: 1.5rem;
      }

      &--me {

        > .str-chat__avatar,
        > .str-chat-angular__avatar-host > .str-chat__avatar {
          margin-bottom: 1.5rem;
        }

        .str-chat__message-text-inner,
        .str-chat__message-attachment--img,
        .str-chat__message-attachment-card {
          border-radius: #{$border-radius-last-bubble-right} !important;
        }
      }
    }

    .str-chat__message-text-inner,
    .str-chat__message-attachment--img,
    .str-chat__message-attachment-card {
      border-radius: #{$border-radius--last-bubble-left};
    }
  }
}
.str-chat__message {
  &-attachment {
    margin: var(--xxs-m) 0;
  }

  &-mention {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  &--me {
    .str-chat__message-attachment {
      margin: 0;
    }

    .str-chat__message {
      &-text-inner {
        flex: initial;
        background: var(--link-bg) !important;
      }
    }
  }
}

.str-chat__message,
.str-chat__message-simple {

  .str-chat__message-simple__actions__action--options {
    button {
      display: flex;
      align-items: center;
    }
  }

  &:hover {

    .str-chat__message-simple__actions__action--options {
      button {
        display: flex;
        align-items: center;
      }
    }

    // todo: this has to be refactored or not styled at all in stream-chat-css
    @media screen and (max-width: 960px) {
      .str-chat__message-simple__actions__action--options {
        display: flex;
      }

      .str-chat__message-simple__actions__action--reactions {
        display: flex;
      }

      .str-chat__message-simple__actions__action--thread {
        display: flex;
      }
    }

    @media screen and (max-width: 768px) {
      .str-chat__message-simple__actions__action--options {
        display: none;
      }

      .str-chat__message-simple__actions__action--reactions {
        display: none;
      }

      .str-chat__message-simple__actions__action--thread {
        display: none;
      }
    }
  }

  &.mobile-press:hover {
    @media screen and (max-width: 768px) {
      .str-chat__message-simple__actions__action--options {
        display: flex;
      }

      .str-chat__message-simple__actions__action--reactions {
        display: flex;
      }

      .str-chat__message-simple__actions__action--thread {
        display: flex;
      }
    }
  }

  &-data {
    position: static;
  }

  &--with-reactions {
    margin-top: var(--lg-m);
  }

  > .str-chat__avatar,
  > .str-chat-angular__avatar-host > .str-chat__avatar {
    margin-bottom: 0;
  }
}

