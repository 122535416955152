@use 'input';

.str-chat__channel-search,
.str-chat .str-chat__channel-search input {
  background: var(--bars-bg) !important;
}

.str-chat__channel-search {
  padding: 1rem 0.8rem 1rem 0.8rem; // TODO: should not be hardcoded

  input {
    @extend %input;
    border: 1px solid var(--borders);
  }
}
