@use "typography";

.str-chat .str-chat__channel-preview-messenger {
  &:hover {
    & .str-chat__channel-preview-messenger--last-message {
      color: var(--text-high-emphasis);
      font-weight: 500;
    }
  }

  &--name {
    @extend %subtitle-1;
    color: var(--text-high-emphasis);
  }

  &--active {
    .str-chat__channel-preview-messenger--last-message {
      color: var(--text-high-emphasis);
      font-weight: 500;
    }
  }

  &--last-message {
    @extend %body-2;
    color: var(--text-low-emphasis);

    p {
      font-size: 14px;
    }
  }
}

