.str-chat__thread {
  margin: 0 !important;
  border-radius: 0 !important;

  max-width: initial !important;
  flex: 0.5 1;
}

@media screen and (max-width: 1280px) {
  .str-chat__thread {
    position: absolute;
    z-index: 100;
    left: 301px;
    width: calc(100% - 301px);
    min-width: calc(100% - 301px);
    height: 100%;
  }
}
@media screen and (max-width: 960px) {
  .str-chat__thread {
    left: 0;
  }
}
