.str-chat__message-attachment-actions {
  &-form {
    width: 100%;
    //margin: 0;
    padding: 0;
    display: flex;
    border-radius: var(--border-radius) var(--border-radius) 0 var(--border-radius)
  }

  &-button {
    flex: 1;
    border: none;
    background: none;
    margin: 0 var(--xxs-m);
    padding: var(--xs-p) var(--xs-p);
    border-radius: var(--border-radius-round);
    outline: none;

    &:focus {
      border: 1px solid var(--primary-color);
      box-shadow: 0 0 0 2px var(--primary-color-faded);
    }

    &--primary {
      background-color: var(--primary-color);
      color: var(--white);
    }

    &--default {
      border: 2px solid var(--border);
    }
  }
}
