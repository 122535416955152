@use "input";

.str-chat__giphy-search {
  width: 100%;
  display: flex;
  align-items: center;
  padding: .8rem;

  .str-chat__giphy-search-input {
    @extend %input;
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: var(--xs-p);

    .str-chat__giphy-search-action-tag {
      display: flex;
      align-items: center;
      border-radius: var(--border-radius-round);
      background: var(--accent-primary);
      color: var(--static-white);
      text-transform: uppercase;
      padding: var(--xxs-p) var(--xs-p);
      font-weight: 500;
      font-size: 1.4rem;
      margin-right: var(--xs-p);

      svg {
        height: 1.3rem;

      }

      path {
        fill: var(--static-white)
      }
    ;
    }

    input {
      outline: none;
      border: none;
      height: 100%;
      width: 100%;
      padding: 0;
      line-height: 2rem;
      font-size: 1.6rem;

      &:active {
        border: none;
      }
    }


  }

  button {
    border: none;
    padding: 0 .6rem;
    height: 4rem;
    background: none;

    &.active svg path:first-of-type {
      fill: var(--accent-primary);
    }
  }

}
