@use 'typography';
@import '~gleap/build/index.min.css'; // bug reporting tool styles (https://gleap.io/docs/javascript/)

html {
  font-size: 62.5%; // 10px

  * {
    box-sizing: border-box;
  }
}

html,
body,
#root,
.light,
.dark,
.str-chat__main-panel,
.str-chat-channel {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
  border: none;
  background: none;
}

%center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
%align-vertical-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wc-auth-page-container {
  height: 100%;
  width: 100%;
  background: var(--bars-bg);
  .wc-auth-page-content {
    @extend %align-vertical-container;
    justify-content: center;
    height: 100%;

    @media screen and (max-width: 960px) {
      justify-content: space-between;
    }
  }
}
