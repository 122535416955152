@use 'button';
@use 'input';

.str-chat__input-flat {
  background: var(--bars-bg) !important;
}

.str-chat__textarea {
  display: flex;
}

.str-chat__fileupload-wrapper {
  right: 25px;
  position: absolute;

  .rfu-file-upload-button {
    position: initial;
    top: initial;
    right: initial;

    span {
      display: flex;
    }
  }
}

.str-chat__input-flat--textarea-wrapper {
  align-items: center;

  .str-chat__emojiselect-wrapper {
    left: 25px;
    position: absolute;

    button {
      display: flex;
      position: initial;
      top: initial;
      left: initial;
    }
  }
}
