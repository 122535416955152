@use 'button';
@use 'dropdown';

.wc-sidebar {
  float: left;
  position: relative;
  height: 100%;
  border-right: 1px solid var(--border-color);

  .search-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.6rem;
    flex-grow: 1;
    height: 5rem;

    .close-mobile-nav-btn {
      @extend %button-light;

      display: none;
      border: none;
      padding: 0.6rem;

      svg {
        path {
          height: 100px;
          fill: var(--text-low-emphasis);
        }
      }

      @media screen and (max-width: 960px) {
        display: flex;
      }
    }
  }

  .str-chat__channel-search-container.inline {
    top: 7rem;
    left: 0;
    width: 100%;
    height: calc(100vh - 7rem);
  }

  .main-menu {
    @extend %dropdown;

    .dropdown-list {
      border-radius: 0 0 4px 4px;
      color: var(--accent_red);
    }

    .menu-btn {
      @extend %button-light;
      border: none;
      padding: 0.2rem;

      svg {
        path {
          fill: var(--text-low-emphasis);
        }
      }
    }
  }
}
